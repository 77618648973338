<template>
  <section>
    <e-filters
      ref="filters"
      :title="$t('Filtros')"
      :searching="fetching"
      :search-text="$t('Pesquisar')"
      @search="filterData"
      @reset="resetFiltersLocal"
    >
      <div>
        <b-row>
          <b-col md="6">
            <e-store-combo
              id="filters-store_origin"
              v-model="filters.storeOriginId"
              :label="$t('Loja Origem')"
              :required="false"
              @input="filters.checkingAccountOriginId = null"
            />
          </b-col>

          <b-col md="6">
            <e-checking-account-combo
              id="filters-checking_account_origin"
              v-model="filters.checkingAccountOriginId"
              :label="$t('Conta corrente Origem')"
              type="All"
              :required="!!filters.storeOriginId"
              :store-id="filters.storeOriginId"
              active-options
            />
          </b-col>
        </b-row>

        <b-row>
          <b-col md="6">
            <e-store-combo
              id="filters-store_destination"
              v-model="filters.storeDestinationId"
              :label="$t('Loja Destino')"
              :required="false"
              @input="filters.checkingAccountOriginId = null"
            />
          </b-col>

          <b-col md="6">
            <e-checking-account-combo
              id="filters-checking_account_destination"
              v-model="filters.checkingAccountDestinationId"
              :label="$t('Conta corrente Destino')"
              type="All"
              :required="!!filters.storeDestinationId"
              :store-id="filters.storeDestinationId"
              active-options
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <FormulateInput
              id="period"
              v-model="filters.period"
              type="date-range-picker"
              :label="$t('Período')"
              class="required"
              :time-picker="false"
              validation="requiredrange"
              :validation-messages="{
                requiredrange: $t('Informe um período'),
              }"
            />
          </b-col>
          <b-col md="2">
            <FormulateInput
              id="transference-imported_file_id"
              v-model="filters.importedFileId"
              :label="$t('Cód. Id. planilha')"
              :instruction="$t('Código de identificação na planilha importada da Prosegur')"
              type="text"
            />
          </b-col>
        </b-row>
      </div>
    </e-filters>

    <b-card-actions
      action-refresh
      :show-loading="fetching"
      @refresh="filterData"
    >
      <div slot="title">
        <e-page-size-selector
          :per-page="paging.pageSize"
          @change="pageSizeChange"
        />
      </div>
      <b-table
        ref="checking-account-table"
        show-empty
        responsive
        striped
        class="bordered"
        :fields="fields"
        :tbody-tr-class="rowClass"
        :items="checkingAccountTransferences"
        :empty-text="getEmptyTableMessage($t('registro'), 'male')"
        no-local-sorting
        :sort-by.sync="sorting.sortBy"
        :sort-desc.sync="sorting.sortDesc"
        @sort-changed="getData"
      >
        <template #cell(value)="row">
          <span :class="row.item.operationType === 'Debit' ? 'text-danger' : 'text-success'">
            {{
              (row.item.operationType === 'Debit' ? row.item.value * -1 : row.item.value) | currency
            }}
          </span>
        </template>

        <template #cell(active)="row">
          <e-status-badge :status="row.item.active" />
        </template>

        <template #cell(origin)="row">
          <b>{{ $t('Loja') }}:</b> {{ row.item.storeOrigin | storeName }}
          <br>
          <b>{{ $t('Conta') }}:</b> {{ row.item.checkingAccountOrigin.name }}
        </template>

        <template #cell(destination)="row">
          <b>{{ $t('Loja') }}:</b> {{ row.item.storeDestination | storeName }}
          <br>
          <b>{{ $t('Conta') }}:</b> {{ row.item.checkingAccountDestination.name }}
        </template>

        <template #cell(infos)="row">
          <div v-if="row.item.documentNumber">
            <b>{{ $t('Documento') }}:</b>
            {{
              row.item.documentType
                ? `${row.item.documentNumber}(${row.item.documentType})`
                : row.item.documentNumber
            }}
            <br>
          </div>
          <b>{{ $t('Descrição') }}:</b> {{ row.item.description }}
          <br>
          <b>{{ $t('Usuário') }}:</b> {{ row.item.user.name }}
        </template>

        <template #cell(action)="row">
          <e-grid-actions
            :show-update="false"
            :show-delete="false"
            :buttons="gridActions(row)"
            @reverse="localReverse(row.item)"
          />
        </template>
      </b-table>
      <b-row>
        <b-col cols="4">
          <e-pagination-summary
            :current-page="paging.currentPage"
            :per-page="paging.pageSize"
            :total="paging.rowCount"
            :total-on-page="paging.rowsInCurrentPage"
          />
        </b-col>
        <b-col cols="4">
          <e-color-subtitled
            :colors="[{ backgroundColor: 'table-danger', title: $t('Estornado') }]"
          />
        </b-col>
        <b-col cols="4">
          <b-pagination
            v-model="paging.currentPage"
            align="right"
            :total-rows="paging.rowCount"
            :per-page="paging.pageSize"
            aria-controls="checking-account-table"
            @change="pageChange"
          />
        </b-col>
      </b-row>
    </b-card-actions>

    <bank-deposit-sidebar
      ref="bankDepositSidebar"
      @save="getData"
    />

    <checking-account-transfer-sidebar
      ref="accountTransferSidebar"
      @save="getData"
    />

    <fab
      v-if="fabActions"
      :main-tooltip="$t('Ações')"
      main-icon="keyboard_command_key"
      :fixed-tooltip="true"
      :actions="fabActions"
      @bulk-deposit="createBankDepositBulk"
      @deposit="createBankDeposit"
      @transference="createAccountTransfer"
    />
  </section>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { BRow, BCol, BPagination, BTable } from 'bootstrap-vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import {
  EPageSizeSelector,
  EPaginationSummary,
  EStoreCombo,
  EStatusBadge,
  EGridActions,
  EFilters,
  ECheckingAccountCombo,
} from '@/views/components'
import EColorSubtitled from '@/views/components/EColorSubtitled.vue'
import fab from '@/views/components/FAB.vue'
import { alerts, financialDomain } from '@/mixins'
import BankDepositSidebar from './BankDepositSidebar.vue'
import CheckingAccountTransferSidebar from './CheckingAccountTransferSidebar.vue'

export default {
  components: {
    BRow,
    BCol,
    EColorSubtitled,
    BCardActions,
    BPagination,
    BTable,
    EPaginationSummary,
    EPageSizeSelector,
    EStatusBadge,
    EStoreCombo,
    EFilters,
    EGridActions,
    ECheckingAccountCombo,
    fab,
    BankDepositSidebar,
    CheckingAccountTransferSidebar,
  },

  mixins: [alerts, financialDomain],

  data() {
    return {
      fetching: false,
    }
  },

  computed: {
    ...mapState('pages/settings/financial/checkingAccountTransfer', [
      'checkingAccountTransferences',
      'reportData',
      'paging',
      'sorting',
      'filters',
      'summaryFields',
    ]),
    fabActions() {
      return [
        {
          name: 'bulk-deposit',
          icon: 'note_add',
          tooltip: this.$t('Importar Depósito Conta Cofre de planilha'),
          hide: !this.$can('BulkCreateFromImport', 'BankDeposit'),
        },
        {
          name: 'deposit',
          icon: 'savings',
          tooltip: this.$t('Adicionar Depósito Conta Cofre'),
          hide: !this.$can('Create', 'BankDeposit'),
        },
        {
          name: 'transference',
          icon: 'account_balance',
          tooltip: this.$t('Adicionar Transferência Bancária'),
          hide: !this.$can('Create', 'CheckingAccountTransfer'),
        },
      ].filter(a => !a.hide)
    },

    gridActions() {
      return row =>
        [
          {
            icon: 'x-circle',
            variant: 'danger',
            title: `${this.$t('Estornar')} ${this.$t(row.item.type)}`,
            event: 'reverse',
            hide:
              !row.item.active ||
              (row.item.type === 'Deposit'
                ? !this.$can('Reverse', 'BankDeposit')
                : !this.$can('Reverse', 'CheckingAccountTransfer')),
          },
        ].filter(btn => !btn.hide)
    },

    fields() {
      return [
        {
          label: this.$t('Ações'),
          key: 'action',
          class: 'text-center',
          thStyle: { width: '50px' },
        },
        {
          label: this.$t('Data'),
          key: 'statementDate',
          thStyle: { width: '150px' },
          tdClass: 'text-center',
          formatter: value => this.$options.filters.date(value),
          sortable: true,
        },
        {
          label: this.$t('Origem'),
          key: 'origin',
          tdClass: 'text-left',
        },
        {
          label: this.$t('Destino'),
          key: 'destination',
          tdClass: 'text-left',
        },
        {
          label: this.$t('Informações'),
          key: 'infos',
          tdClass: 'text-left',
        },
        {
          label: this.$t('Valor'),
          key: 'value',
          tdClass: 'text-right',
          thStyle: { width: '120px' },
          formatter: val => this.$options.filters.currency(val),
        },
      ]
    },
  },

  mounted() {
    // this.getData()
  },

  methods: {
    ...mapActions('pages/settings/financial/checkingAccountTransferDepositBulkMaintain', {
      stCleanDepositBulkMaintain: 'cleanState',
    }),
    ...mapActions('pages/settings/financial/checkingAccountTransfer', [
      'fetchCheckingAccountTransferences',
      'generateReport',
      'setCurrentPage',
      'setPageSize',
      'resetFilters',
    ]),
    async getData() {
      this.$nextTick(async () => {
        try {
          this.fetching = true
          await this.fetchCheckingAccountTransferences()
        } catch (error) {
          this.showGenericError({ error })
        } finally {
          this.fetching = false
        }
      })
    },

    rowClass(item) {
      if (item?.active === false) return 'table-danger'
      return ''
    },

    async localReverse(transfer) {
      const confirmed = await this.confirm({
        text: this.$t(
          transfer.type === 'Deposit'
            ? 'O depósito será estornado e os lançamentos nas contas correntes serão excluídos.'
            : 'A transferência será estornada, os lançamentos nas contas correntes e contas a pagar e receber serão excluídos.'
        ),
      })
      if (!confirmed) return

      try {
        this.fetching = true
        const url = transfer.type === 'Deposit' ? 'bank-deposit' : 'account-transfer'
        await this.$http.patch(`/api/financial/${url}/${transfer.id}/reverse`)

        this.showSuccess({ message: this.$t('Transação estornada com sucesso') })
        await this.getData()
      } catch (error) {
        this.showError({ error })
      } finally {
        this.fetching = false
      }
    },

    createBankDeposit() {
      this.$refs.bankDepositSidebar.onShowSidebar()
    },
    createBankDepositBulk() {
      this.stCleanDepositBulkMaintain()
      this.$router.push({ name: 'checking-account-bank-deposit-bulk' })
    },
    createAccountTransfer() {
      this.$refs.accountTransferSidebar.onShowSidebar()
    },

    resetFiltersLocal() {
      this.resetFilters()
      this.getData()
    },
    filterData() {
      this.getData()
    },

    pageSizeChange(pageSize) {
      this.setPageSize(pageSize)
      this.getData()
    },

    pageChange(currentPage) {
      this.setCurrentPage(currentPage)
      this.getData()
    },

    showCheckingAccountInfo(item) {
      this.$refs.checkingAccountInfoSidebar.show(item)
    },
  },
}
</script>

<style></style>
